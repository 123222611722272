import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./scss/style.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import axios from "axios";
import io, { Socket } from "socket.io-client";
const newSocket = io(process.env.REACT_APP_BASE_URL_BACKEND_NUMBERS);
const authSocket = io(process.env.REACT_APP_BASE_URL_AUTH);
export {authSocket}


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page404Chat = React.lazy(() =>
  import("./views/pages/page404/Page404Chat")
);
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/login/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/login/ResetPassword")
);
const NewPassword = React.lazy(() =>
  import("./views/pages/login/creerNouveauMotPasse")
);

const PrivateRouteX = ({ render: Component, auth, ...rest }) => {
  const [dd, setdd] = useState();
  const [RoleUser, setRoleUser] = useState();
  const [Auth, setAuth] = useState(async () => {
    if (localStorage.getItem("token")) {
      var data = localStorage.getItem("token");
      data = data.substr(1, data.length - 2);
      var c = await axios
        .get(`${process.env.REACT_APP_BASE_URL_AUTH}/getUserConnecter`, {
          headers: {
            "x-access-token": data,
          },
        })
        .then((res, err) => {
          return { auth: true, RoleUser: res.data.Role.name };
        })
        .catch((err) => {
          return { auth: false, RoleUser: "" };
        });
    } else {
      return { auth: false, RoleUser: "" };
    }

    return c;
  });

  Auth.then(
    function (v) {
      setdd(v.auth);
      setRoleUser(v.RoleUser);
      return !v;
    },
    function (e) {
      setdd(false);
      return false;
    }
  );
  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          dd ? (
            RoleUser === "admin" ? (
              <Redirect to="/dashboard/admin" />
            ) : RoleUser === "user" ? (
              <Redirect to="/dashboard/agent" />
            ) : RoleUser === "super-admin" ? (
              <Redirect to="/dashboard/superadmin" />
            ) : (
              console.log(" ")
            )
          ) : (
            <Component {...props} />
          )
        }
      />
    </>
  );
};
{
}

const PrivateRouteYY = ({ render: Component, ...rest }) => {
  const [ResetPassword, setResetPassword] = useState();
  const [RechercheResetPassword, setRechercheResetPassword] = useState(
    async () => {
      var v = await axios
        .get(
          `${process.env.REACT_APP_BASE_URL_AUTH}/auth/RechercheResetPassword/${rest.computedMatch.params.token}`
        )
        .then((response) => {
          if (response.status === 200) return false;
          if (response.status === 400) return true;
        })
        .catch((err) => {
          return true;
        });

      return v;
    }
  );

  RechercheResetPassword.then(
    function (v) {
      setResetPassword(v);
      return v;
    },
    function (e) {
      setResetPassword(false);
      return false;
    }
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        ResetPassword ? <Redirect to="/404" /> : <Component {...props} />
      }
    />
  );
};
function App() {
  const [id,setId] = useState()

  useEffect(() => {
    axios.defaults.headers.common["x-access-token"] = localStorage.getItem(
      "token"
    )
      ? localStorage.getItem("token").replaceAll('"', "")
      : null;
    axios.defaults.headers.common["token"] = localStorage.getItem("token")
      ? localStorage.getItem("token").replaceAll('"', "")
      : null;
  }, []);

const userId = localStorage.getItem("userId")
  useEffect(() => {
    if (userId) {
      newSocket.emit("online", {
        userId
      });
    }
    const handleBeforeUnload = (event) => {
     // Perform actions before the component unloads
      if (userId) {
        newSocket.emit("offline", {
          userId,
          username: localStorage.getItem("username").replaceAll('"', ""),
        });
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [userId]);

  useEffect(()=>{
      authSocket.on(`confirmLogin`,(data)=>{
        setId(data.user)
      })
    
    return ()=>{
      authSocket.off('confirmLogin')
    }
  },[])
  useEffect(()=>{
    if(userId===id){
      localStorage.clear()
      window.location.href='/login'
    }
  },[id])

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <PrivateRouteX
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/forgotPassword"
            name="Forgot Password Page"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            exact
            path="/auth/newPassword/:token"
            name="New Password Page"
            render={(props) => (
              <NewPassword {...props} token={props.match.params.token} />
            )}
          />

          <PrivateRouteYY
            exact
            path="/auth/resetpassword/:token"
            name="Reset Password Page"
            render={(props) => (
              <ResetPassword {...props} token={props.match.params.token} />
            )}
          />
          <PrivateRouteX
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/room_not_found"
            name="Page 404"
            render={(props) => <Page404Chat {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
